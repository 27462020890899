
import React, { useEffect } from "react";
import ZenDesk from "../components/zendesk/zendesk";
import { navigate, withPrefix } from "gatsby";
import Footer1 from "../components/footer-1";
import Header from "../components/header";
export default function Help() {
  return (
    <div className="container-main" id="page">
      <Header></Header>
      <main className="content-main">
        <div className="row">
          <div className="col">
            <div className="policies-main px-3 py-5 mb-5">
              <h3>Frequently Asked Questions (FAQ)</h3>
              <h3>1. What is 99GENS?</h3>
              <p>
                99GENS is a platform that allows users to generate or submit art
                and quickly publish 30 (and growing) Print-on-demand merchandise
                products such as All-over Print backpacks, hoodies, sweatshirts,
                jackets, dresses and more. In less than 60 seconds you can
                preview and purchase your artwork on multiple print-on-demand
                products.
              </p>
              <h3>2. How does the 99GENS service work?</h3>
              <p>
                99GENS automatically generates the print files that
                Print-on-demand drop-shipping providers require in order to
                transfer your art onto their merch. We instantly upload those
                files to the appropriate manufacturer for each product and
                ensure they meet the printing guidelines. We call this process
                Merchification.
              </p>
              <h3>3. Is it free?</h3>
              <p>
                Right now merchification with 99GENS is free for private preview
                users who upload outside art. Generating AI images will burn
                your credits. Merchify to your heart’s content!
              </p>
              <h3>4. What are Credits?</h3>
              <p>
                New accounts are currently provided 20 free credits. Credits are
                used to generate AI images and will also be used to purchase
                premium features as we add them. You can purchase more credits
                through our website by clicking on PRICING, selecting the
                desired credit package and completing the payment process. We
                process all payments with Stripe.
              </p>
              <h3>5. How long does it take to receive purchased items?</h3>
              <p>
                Items are typically processed and shipped within 7-14 days but
                it depends on the product and printing partner. We only partner
                with the best print-on-demand manufacturers.
              </p>
              <h3>6. How do I view products I’ve generated?</h3>
              <p>
                You can click on My Creations to view all of your historical
                merchifications.
              </p>
              <h3>7. How do I purchase a product?</h3>
              <p>
                When you are viewing a page of products, select a product and
                click Add to Cart for that product. You can do this for multiple
                products on the page. When you are ready to checkout, click NEXT
                in the bottom right and click Add to Cart once again. This will
                advance you through the checkout process.
              </p>
            </div>
          </div>
        </div>
      </main>
      <ZenDesk />
      <Footer1></Footer1>
    </div>
  );
}
