import React, { useEffect } from "react";
import Zendesk from "react-zendesk";
const ZENDESK_KEY = "1dee2788-fb80-48f4-84db-56924217b8eb";
const setting = {
  color: {
    theme: "#6204AA",
  },
  webWidget: {
    contactOptions: {
      enabled: true,
      contactButton: { "*": "Get in touch" },
      chatLabelOnline: { "*": "Live Chat" },
      chatLabelOffline: { "*": "Live Chat is unavailable" },
    },
    helpCenter: {
      chatButton: {
        "*": "Chat with us now",
      },
    },
    chat: {
      profileCard: {
        avatar: true,
        rating: false,
        title: true,
      },
    },
  },
};

const handleLoadEvent = () => {
  window.zE("webWidget:on", "close", function () {
    console.log("Widget closed!");
  });
  console.log("setting suggestion...");
  window.zE("webWidget", "helpCenter:setSuggestions", {
    search: "what is 99gens?",
  });
};

export default function App() {
  return (
    <div className="App">
      <Zendesk
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={handleLoadEvent}
      />
    </div>
  );
}
